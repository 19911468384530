import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, IconButton } from '@mui/material';
import AddAlarmIcon from '@mui/icons-material/Alarm';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  BirdzTitle,
  ListPage,
  AppPaper,
  ListField,
  SearchField
} from '@applications-terrains/birdz-react-library';
import {
  formatToSelectOptions,
  useGetCaseStatuses,
  useGetCaseTypes,
  useGetCrCities,
  useGetCrContracts
} from '../../../hooks/datarefs';

const CrCases = () => {
  const endpoint = '/api/boi/cases/cr-cases/';
  const { data: caseStatuses } = useGetCaseStatuses();
  const { data: caseTypes } = useGetCaseTypes();
  const { data: contracts } = useGetCrContracts();
  const { data: cities } = useGetCrCities();

  const [listFields, setListFields] = useState<ListField[]>([]);
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);

  useEffect(() => {
    const listFields = [
      {
        name: 'number',
        label: 'Numéro de dossier'
      },
      {
        name: 'type__name',
        label: 'Type'
      },
      {
        name: 'pdc_pdr',
        label: 'PDC/ PDR'
      },
      {
        name: 'city',
        label: 'Commune'
      },
      {
        name: 'device_id',
        label: 'Adresse Radio'
      },
      {
        name: 'contract',
        label: 'Code-Libellé Contrat',
        transform: (value: any, item: any) => {
          return item.contract_code + ' - ' + item.contract_label;
        }
      },
      {
        name: 'status__name',
        label: 'Statut'
      },
      {
        name: 'last_intervention_date',
        label: 'Intervention associée',
        className: 'text-center',
        transform: (date: string, row: any) => {
          return row.last_intervention_date ? (
            <>
              {date}
              <Link to={`/boi/cr/cases/edit/${row.id}`}>
                <IconButton aria-label="Intervention détail" id="intervention_detail">
                  <VisibilityIcon id="visibilityIcon" />
                </IconButton>
              </Link>
            </>
          ) : (
            <Link to={`/boi/cr/cases/edit/${row.id}`}>
              <IconButton aria-label="ajouter une intervention" id="intervention_detail">
                <AddAlarmIcon id="alarmIcon" />
              </IconButton>
            </Link>
          );
        }
      }
    ];

    const searchFields = [
      {
        name: 'number',
        id: 'search_by_number',
        label: 'Numéro de dossier',
        options: {
          identifier: 'number',
          source: endpoint,
          label: 'number',
          searchIsMulti: false
        }
      },
      {
        name: 'pdc_pdr',
        id: 'search_by_pdc',
        label: 'Numéro PDC',
        type: 'text'
      },
      {
        name: 'device_id',
        id: 'search_by_device',
        label: 'Numéro CR',
        type: 'text'
      },
      {
        name: 'contract_code_label',
        id: 'search_by_contract',
        label: 'Code contrat',
        type: 'select',
        options: {
          values:
            contracts && contracts.length
              ? contracts?.map((contract: any) => {
                  return {
                    value: contract,
                    label: contract
                  };
                })
              : []
        }
      },
      {
        name: 'city_name',
        label: 'Commune',
        type: 'select',
        options: {
          values:
            cities && cities.length
              ? cities.map((city: any) => {
                  return {
                    value: city,
                    label: city
                  };
                })
              : []
        }
      },
      {
        name: 'subcontractor',
        id: 'search_by_subcontractor',
        label: 'Sous-traitant',
        options: {
          identifier: 'id',
          label: 'name',
          isMulti: false,
          source: '/api/boi/subcontractors/subcontractors/?is_active=true&'
        }
      },
      {
        name: 'with_intervention',
        id: 'search_by_interventions',
        label: 'Avec interventions',
        type: 'select',
        options: {
          values: [
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
          ]
        }
      },
      {
        name: 'status',
        id: 'search_by_status',
        label: 'Statut dossier',
        type: 'select',
        options: {
          values: formatToSelectOptions(caseStatuses)
        }
      },
      {
        name: 'type',
        id: 'search_by_type',
        label: 'Type',
        type: 'select-multiple',
        options: {
          values: formatToSelectOptions(caseTypes)
        }
      },
      {
        name: 'created_at_after',
        id: 'search_by_date_after',
        label: 'Entre le ',
        type: 'datepicker',
        options: {
          format: 'YYYY-MM-DD'
        }
      },
      {
        name: 'created_at_before',
        id: 'search_by_date_before',
        label: 'et le',
        type: 'datepicker',
        options: {
          format: 'YYYY-MM-DD'
        }
      }
    ];

    setListFields(listFields);
    setSearchFields(searchFields);
  }, [caseStatuses, caseTypes, contracts]);

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des dossiers</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/boi/cr/diagnostics">
            <Button variant="contained" sx={{ mt: 3 }} id="new_folder">
              <AddCircleIcon sx={{ mr: 1 }} /> Nouveau dossier
            </Button>
          </Link>
        </Grid>
      </Grid>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        searchFields={searchFields}
        paginationOptions={{
          pageSize: 25
        }}
        defaultOrder={['-updated_at']}
      />
    </AppPaper>
  );
};

export default CrCases;
