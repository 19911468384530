import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './routes';
import * as _ from 'lodash';
import { ThemeProvider } from '@mui/material';
import { boeTheme, boiTheme } from './styles/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { authService } from '.';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/sw.js', {
        scope: '/'
      });
    } catch (error) {
      //eslint-disable-next-line
    }
  }
};

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    try {
      createNotificationSubscription().then((pushSubscription) => {
        if (pushSubscription !== undefined) {
          axios
            .post('/api/boi/notifications/save-subscription/', pushSubscription.toJSON())
            //eslint-disable-next-line
            .then(() => {});
        }
      });
    } catch (e) {
      //eslint-disable-next-line
    }
  }
};

const createNotificationSubscription = async (): Promise<PushSubscription | void> => {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;

  return new Promise((resolve) => {
    serviceWorker.pushManager.getSubscription().then(async (subscription) => {
      if (subscription === null) {
        const response = await axios.get('/api/boi/notifications/public-key/');
        if (!response.data.vapid_public_key) throw new Error('no vapid public key');

        serviceWorker.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: response.data.vapid_public_key
          })
          .then((pushSubscription: PushSubscription) => {
            resolve(pushSubscription);
          });
      } else {
        resolve();
      }
    });
  });
};

const enableNotifications = async () => {
  registerServiceWorker();
  requestNotificationPermission();
};

const App = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const userPermissions = authService?.user?.permissions || [];
  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    async function getUserInfo() {
      await authService?.getUserInfo();
      if (
        authService?.isAuthenticated &&
        !authService.canAccessBOI() &&
        !authService.canAccessBOE()
      ) {
        notif({ type: 'error', content: "Vous n'avez pas accès à cette application" });
        setIsReady(false);
        return;
      }

      if (authService?.canAccessBOI()) {
        enableNotifications();
      }

      setIsReady(true);
    }

    if (!authService?.initialized) {
      getUserInfo();
    } else {
      setIsReady(true);
    }
  }, [window.location]);

  const displayRoutes = (routes: any) => {
    return routes.map((route: any, index: number) => {
      if (route.permissions && authService?.isAuthenticated) {
        const permissionsGranted =
          _.intersection(userPermissions, route.permissions).length === route.permissions.length;

        if (!permissionsGranted) {
          return null;
        }
      }

      if (route.path) {
        return (
          <Route key={route.path + index} path={route.path} element={route.element}>
            {route.children && route.children.length > 0 ? displayRoutes(route.children) : null}
          </Route>
        );
      } else {
        return <Route index element={route.element} key={`route-index-${index}`} />;
      }
    });
  };

  const queryClient = new QueryClient();

  return (
    <ThemeProvider
      theme={
        authService.canAccessBOI() ? boiTheme : authService.canAccessBOE() ? boeTheme : boiTheme
      }
    >
      <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          {isReady && (
            <BrowserRouter>
              <Routes>{displayRoutes(routes)}</Routes>
            </BrowserRouter>
          )}
          <BirdzNotif options={notifOptions} />
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
