import React, { useEffect, useState } from 'react';
import { BirdzDialog, useDialog, ListPage } from '@applications-terrains/birdz-react-library';
import { Link, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import Visibility from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Select from 'react-select';
import { Client } from './ClientsForm';
import { Erp } from '../Erps/ErpForm';
import { getFormattedContacts } from './utils';

const AssociatedERPs = ({ client }: { client: Client }) => {
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const { id } = useParams();

  const [availableERPs, setAvailableERPs] = useState([]);
  const [erpToAdd, setErpToAdd] = useState<{ name: string; id: number } | null>(null);

  useEffect(() => {
    if (id) {
      axios.get(`/api/boi/extractor/erps`).then((response: AxiosResponse) => {
        const erps = response.data.results;
        setAvailableERPs(erps.filter((erp: Erp) => !client.erp?.includes(erp.id)));
      });
    }
  }, [id]);

  return (
    <>
      <Grid justifyContent="space-between" container sx={{ mt: 6 }}>
        <Grid item>
          <h4>Liste des ERP associés</h4>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <div style={{ display: 'flex' }}>
            <Select
              name="erps"
              options={availableERPs.map((erp: Erp) => ({
                label: erp.name,
                value: erp.id
              }))}
              onChange={(selectedOption) => {
                setErpToAdd({
                  name: selectedOption?.label as string,
                  id: selectedOption?.value as number
                });
              }}
            />
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              disabled={!erpToAdd}
              onClick={() => {
                erpToAdd &&
                  confirmDialog({
                    title: 'Associer un ERP',
                    content: `Êtes-vous sûr de vouloir associer l'ERP ${erpToAdd.name} ?`,
                    onValidate: () => {
                      const payload = {
                        ...client,
                        ...getFormattedContacts(client),
                        erp: [...(client.erp as []), erpToAdd.id]
                      };
                      axios.put(`/api/boi/extractor/clients/${id}`, payload).then(
                        () => {
                          notif({
                            content: `L'ERP ${erpToAdd.name} a été associé avec succès`,
                            type: 'success'
                          });
                          document.location.reload();
                          setErpToAdd(null);
                        },
                        () => {
                          notif({
                            content: 'Une erreur est survenue lors de la mise à jour',
                            type: 'error'
                          });
                        }
                      );
                      closeDialog();
                    },
                    onCancel: () => {
                      closeDialog();
                    }
                  });
              }}
            >
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un ERP
            </Button>
          </div>
        </Grid>
      </Grid>
      <ListPage
        loadedData={client?.erp_objects || []}
        actions={[
          {
            name: 'voir',
            render: (erp: { name: string; description: string; id: number | string }) => {
              return (
                <IconButton component={Link} to={`/boi/extractor/erps/edit/${erp.id}`}>
                  <Visibility fontSize="small" />
                </IconButton>
              );
            }
          },
          {
            name: 'delete-erp',
            label: "Supprimer l'association avec l'ERP",
            render: (erp: { name: string; description: string; id: number | string }) => {
              return (
                <IconButton
                  onClick={() => {
                    confirmDialog({
                      title: "Supprimer l'association avec un ERP",
                      content: `Êtes-vous sûr de vouloir supprimer l'association avec l'ERP ${erp.name} ?`,
                      onValidate: () => {
                        const payload = {
                          ...client,
                          erp: client.erp?.filter((erpId) => erpId !== erp.id)
                        };
                        axios.put(`/api/boi/extractor/clients/${id}`, payload).then(
                          () => {
                            notif({
                              content: `L'association avec l'ERP ${erp.name} a été supprimée avec succès`,
                              type: 'success'
                            });
                            document.location.reload();
                          },
                          () => {
                            notif({
                              content: 'Une erreur est survenue lors de la suppression',
                              type: 'error'
                            });
                          }
                        );
                        closeDialog();
                      },
                      onCancel: () => {
                        closeDialog();
                      }
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              );
            }
          }
        ]}
        fields={[
          {
            name: 'name',
            label: 'Nom'
          },
          {
            name: 'description',
            label: 'Description'
          }
        ]}
        displayResultsNumber={false}
        displayPaginationOptions={false}
      />
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default AssociatedERPs;
