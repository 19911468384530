import React from 'react';
import { FetchContextProvider } from './context';
import CorrespondancesList from './list/CorrespondancesList';

const CorrespondancesPage = () => {
  return (
    <FetchContextProvider>
      <CorrespondancesList />
    </FetchContextProvider>
  );
};

export default CorrespondancesPage;
