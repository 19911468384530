import React, { useEffect, useState } from 'react';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';
import axios from 'axios';
import { Button, CircularProgress, Alert, IconButton, Grid } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import FileDownload from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Error from '@mui/icons-material/Error';
import csvIcon from '../../../../assets/icons/csv.png';
import './import.scss';
import { useFetchContext } from '../context';
// import ContentCopy from '@mui/icons-material/ContentCopy';
import DndOrImport from './DndOrImport';

export type NewFile = { type: 'file'; content: File } | { type: 'string'; content: string } | null;

const ImportHandler = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFile, setNewFile] = useState<NewFile>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formatError, setFormatError] = useState<boolean>(false);

  const fetchContext = useFetchContext();

  const { notif, notifOptions } = useNotif();

  const title = 'Importer';

  const handleClose = () => {
    setIsModalOpen(false);
    setNewFile(null);
    setIsLoading(false);
    setFormatError(false);
  };

  useEffect(() => {
    if (newFile?.type === 'file') {
      const extension = newFile?.content.name.split('.').pop();
      setFormatError(extension !== 'csv');
    } else setFormatError(false);
  }, [newFile]);

  const readBinaryFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFile = () => {
    if (!newFile) return;
    else if (newFile.type === 'file') {
      readBinaryFile(newFile.content).then((file) => {
        axios({
          method: 'POST',
          url: `/api/boi/extractor/import-data-mapping/`,
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `attachment; filename=${newFile.content.name}`
          },
          data: file
        })
          .then(() => {
            handleClose();
            notif({
              content: 'Import des données réalisé avec succès',
              type: 'success'
            });
            fetchContext.toggleNeedToFetch(true);
          })
          .catch(() => {
            setIsLoading(false);
            notif({
              content: "Une erreur est survenue lors de l'import des données",
              type: 'error'
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  };

  return (
    <>
      <BirdzNotif options={notifOptions} />
      <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={() => setIsModalOpen(true)}>
        <FileDownload sx={{ mr: 1 }} /> {title}
      </Button>
      <BirdzModal open={isModalOpen} onClose={handleClose}>
        <BirdzModalTitle onClose={handleClose}>Ajouter un fichier</BirdzModalTitle>
        <BirdzModalContent>
          <div className="content w-100 d-flex justify-content-center align-items-center">
            {!newFile ? (
              <div className="d-flex flex-column align-items-center">
                <DndOrImport
                  setNewFile={(file: File) => setNewFile({ type: 'file', content: file })}
                />

                {/* <p>ou</p>
                <Button
                  id="copy/paste"
                  className="copy-paste-button"
                  variant="outlined"
                  component="label"
                  size="small"
                  onClick={() => setNewFile({ type: 'string', content: '' })}
                >
                  <ContentCopy sx={{ mr: 1 }} />
                  coller les données
                </Button> */}
              </div>
            ) : newFile?.type === 'file' ? (
              <Grid container spacing={2} className="d-flex align-items-center">
                <Grid item xs={2}>
                  fichier choisi :
                </Grid>
                <Grid item xs={9} className="file-container p-50 mt-1">
                  <div className="file-wrapper d-flex align-items-center">
                    <div className="mr-1">
                      {formatError ? (
                        <Error sx={{ color: 'red', fontSize: '40px' }} />
                      ) : (
                        <img src={csvIcon} className="icon" />
                      )}
                    </div>
                    <div className="d-flex">
                      <div className="file-name">{newFile?.content.name}</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="delete-file"
                    color={'inherit'}
                    onClick={() => setNewFile(null)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={9} className="p-0">
                  {formatError && (
                    <Alert severity="error" sx={{ mt: 1 }}>
                      le format doit être de type .csv
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            ) : (
              <textarea
                name="copy/paste"
                rows={7}
                style={{ width: '500px', marginTop: '7px' }}
                onChange={(e: any) => setNewFile({ type: 'string', content: e.target.value })}
                placeholder="coller les données ici"
              />
            )}
          </div>
        </BirdzModalContent>
        <BirdzModalActions>
          <Button id="cancelUpload" variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            id="confirmUpload"
            variant="contained"
            onClick={uploadFile}
            disabled={formatError || isLoading || newFile === null || !newFile.content}
          >
            <div className="confirm-button-icon">
              {isLoading ? (
                <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />
              ) : (
                <FileDownload sx={{ mr: 1 }} />
              )}
            </div>
            {title}
          </Button>
        </BirdzModalActions>
      </BirdzModal>
    </>
  );
};

export default ImportHandler;
