import React, { useEffect } from 'react';
import './WaterDrop.scss';
import waterBloop from './water-bloop.mp3';

export default function WaterDrop() {
  const WATERDROP_DURATION_MILLISECONDS = 2000;
  const WATERDROP_COUNT = 1;

  useEffect(() => {
    const audio = new Audio(waterBloop);
    audio.loop = false;

    let counter = 0;
    const interval = setInterval(() => {
      audio.play();
      counter++;
      if (counter === WATERDROP_COUNT) {
        clearInterval(interval);
      }
    }, WATERDROP_DURATION_MILLISECONDS);
  }, []);

  return (
    <>
      <div
        className="waterdrop"
        ref={(el) => {
          if (el) {
            el.style.setProperty('--animation-duration', `${WATERDROP_DURATION_MILLISECONDS}ms`);
            el.style.setProperty('--animation-count', `${WATERDROP_COUNT}`);
          }
        }}
      >
        <div className="drop"></div>
        <div className="wave"></div>
      </div>
    </>
  );
}
