import axios from 'axios';

export const downloadFile = (url: string, fileName: string) => {
  axios({
    //Found on stackoverflow -> https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
    url: url,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};
