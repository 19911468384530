import React, { ReactNode, createContext, useContext, useState } from 'react';

type FetchContextType = {
  needToFetch: boolean;
  toggleNeedToFetch: (value: boolean) => void;
};

const FetchContext = createContext<FetchContextType | undefined>(undefined);

export const useFetchContext = () => {
  const context = useContext(FetchContext);

  if (context === undefined) {
    throw new Error('useFetchContext must be used with a FetchContext');
  }

  return context;
};

export const FetchContextProvider = ({ children }: { children: ReactNode }) => {
  const [needToFetch, setNeedToFetch] = useState(true);
  const toggleNeedToFetch = (value: boolean) => {
    setNeedToFetch(value);
  };

  const value = {
    needToFetch,
    toggleNeedToFetch
  };

  return <FetchContext.Provider value={value}>{children}</FetchContext.Provider>;
};
