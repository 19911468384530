import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle,
  BirdzTitle,
  InputField
} from '@applications-terrains/birdz-react-library';
import { Alert, Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ExportFormatSettingsType } from '../ExportFormatSettings';
import * as Yup from 'yup';
import { ExportFormatSettingsText } from './ExportFormatSettingsText';
import { ExportFormatSettingsNumber } from './ExportFormatSettingsNumber';
import { ExportFormatSettingsDate } from './ExportFormatSettingsDate';
import { ExportFormatSettingsBoolean } from './ExportFormatSettingsBoolean';

type ExportFormatSettingsTextProps = {
  onSubmit: (formValues: Partial<ExportFormatSettingsType>) => Promise<any>;
  onClose: () => void;
  settings: Partial<ExportFormatSettingsType>;
};

export const ExportFormatBase = ({
  onClose,
  onSubmit,
  settings
}: ExportFormatSettingsTextProps) => {
  const [modalOpened, setModalOpened] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setModalOpened(false);
    setTimeout(() => {
      setModalOpened(true);
    });
  }, [settings]);

  return (
    <Formik
      initialValues={settings}
      enableReinitialize={true}
      onSubmit={async (values: any) => {
        onSubmit(values).then(
          () => {
            setModalOpened(false);
          },
          () => {
            setError(`Une erreur est survenue lors de l'ajout de la colonne`);
          }
        );
      }}
      validationSchema={Yup.object().shape({
        export_field_name: Yup.string().required("Vous devez renseigner le nom de l'export")
      })}
    >
      {(props: any) => {
        const { errors, setSubmitting, handleSubmit } = props;
        return (
          <form
            id="modalForm"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              setSubmitting(true);
              // Check
              if (Object.keys(errors).length === 0) {
                handleSubmit();
              }
            }}
          >
            <BirdzModal
              open={modalOpened}
              // open={true}
              onClose={() => {
                onClose();
              }}
              maxWidth="md"
            >
              <BirdzModalTitle
                onClose={() => {
                  onClose();
                }}
              >
                {settings?.field_to_export_object?.name} : {settings?.field_to_export_object?.type}
              </BirdzModalTitle>
              <BirdzModalContent>
                {error && <Alert severity="error">{error}</Alert>}

                {settings?.field_to_export_object?.type === 'TEXT' && <ExportFormatSettingsText />}
                {settings?.field_to_export_object?.type === 'NUMBER' && (
                  <ExportFormatSettingsNumber />
                )}
                {settings?.field_to_export_object?.type === 'DATE' && <ExportFormatSettingsDate />}
                {settings?.field_to_export_object?.type === 'BOOLEAN' && (
                  <ExportFormatSettingsBoolean />
                )}

                <BirdzTitle>Export</BirdzTitle>
                <Grid container alignItems="center" spacing={1}>
                  <Grid alignItems={'center'} item xs={4}>
                    Nom de la colonne
                  </Grid>
                  <Grid alignItems={'center'} item xs={8}>
                    <InputField name="export_field_name" fullWidth size="small" />
                  </Grid>
                </Grid>
              </BirdzModalContent>
              <BirdzModalActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button type="submit" form="modalForm">
                  Envoyer
                </Button>
              </BirdzModalActions>
            </BirdzModal>
          </form>
        );
      }}
    </Formik>
  );
};
