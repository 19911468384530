import React, { useEffect, useState } from 'react';
import { BirdzTitle, SelectField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const ExportFormatSettingsMapping = () => {
  const [dataMapping, setDataMapping] = useState([]);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    axios.get(`/api/boi/extractor/data-mapping/?erp=${id}`).then((response) => {
      setDataMapping(response?.data?.results || []);
    });
  }, [id]);
  return (
    <>
      <BirdzTitle>Mapping</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Correspondance
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <SelectField
            name="data_mapping"
            options={
              dataMapping && dataMapping.length > 0
                ? dataMapping.map((mapping: any) => {
                    return {
                      value: mapping.id,
                      label: `${mapping.name} (#${mapping.id})`
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
