import React, { useEffect, useState } from 'react';
import {
  BirdzDialog,
  useDialog,
  SelectField,
  InputField,
  ListPage
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Box, Button, CircularProgress, Grid, IconButton, Paper } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import {
  formatToSelectOptions,
  useGetAnomalyProfiles,
  useGetModuleFamilies
} from '../../../hooks/datarefs';
import { Formik } from 'formik';
import { Job } from '../Jobs/JobForm';
import Visibility from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ClientNameField from './ClientNameField';
import AssociatedERPs from './AssociatedERPs';
import { getFormattedContacts } from './utils';
import './clients.scss';
import CustomSwitchField from '../../CustomSwitchField/CustomSwitchField';

type ParamTypes = {
  id: string;
};

export type Client = {
  name?: string;
  description?: string;
  inter_auto_validation?: boolean;
  module_families?: number[];
  inter_issue_contacts?: string;
  inter_issue_contact_frequency?: string;
  export_issue_contacts?: string;
  export_success_contacts?: string;
  erp?: number[];
  anomaly_profile?: number;
  erp_objects?: Array<{
    name: string;
    description: string;
  }>;
  jobs?: Job[];
  updated_at?: number;
};

type ClientApi = {
  name?: string;
  description?: string;
  inter_auto_validation?: boolean;
  module_families?: string[] | undefined;
  inter_issue_contacts?: string[];
  inter_issue_contact_frequency?: string;
  export_issue_contacts?: string[];
  export_success_contacts?: string[];
  erp?: number[];
  anomaly_profile?: number;
  erp_objects?: Array<{
    name: string;
    description: string;
  }>;
  jobs_count?: number;
  jobs?: Job[];
};

const ClientForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [client, setClient] = useState<Client>({});

  const { notif, notifOptions } = useNotif();
  const { data: moduleFamilies } = useGetModuleFamilies();
  const { data: anomalyProfiles } = useGetAnomalyProfiles();
  const { id } = useParams<ParamTypes>();
  const navigate = useNavigate();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  useEffect(() => {
    if (!moduleFamilies || !anomalyProfiles) {
      return;
    }

    if (id) {
      setIsLoading(true);
      axios.get(`/api/boi/extractor/clients/${id}/`).then((response: AxiosResponse<ClientApi>) => {
        const client = formatApiResponse(response.data);
        setClient(client);
        setIsLoading(false);
      });
    }
  }, [id, moduleFamilies, anomalyProfiles]);

  const formatApiResponse = (clientApi: ClientApi): Client => {
    const client: Client = {
      ...clientApi,
      inter_issue_contacts: clientApi.inter_issue_contacts?.join('\n'),
      export_issue_contacts: clientApi.export_issue_contacts?.join('\n'),
      export_success_contacts: clientApi.export_success_contacts?.join('\n'),
      module_families:
        (clientApi.module_families
          ?.map((moduleFamilyName: string) => {
            const foundModuleFamily = moduleFamilies?.find(
              (moduleFamily: { Id: number; Name: string }) => moduleFamily.Name === moduleFamilyName
            );
            return foundModuleFamily?.Id;
          })
          .filter((familyId: number | undefined) => familyId) as number[]) || []
    };

    return client;
  };

  const saveClient = (client: Client) => {
    setSubmitInProgress(true);
    const contacts = getFormattedContacts(client);
    const clientApi: ClientApi = {
      ...client,
      ...contacts,
      module_families:
        (client.module_families
          ?.map((moduleFamilyId: number) => {
            const foundModuleFamily = moduleFamilies?.find(
              (moduleFamily: { Id: number; Name: string }) => moduleFamily.Id === moduleFamilyId
            );
            return foundModuleFamily?.Name;
          })
          .filter((familyName: string | undefined) => familyName) as string[]) || []
    };
    const method = id ? 'put' : 'post';
    const url = id ? `/api/boi/extractor/clients/${id}/` : '/api/boi/extractor/clients/';
    axios[method](url, clientApi)
      .then(() => {
        notif({
          type: 'success',
          content: 'Le client a bien été enregistré'
        });
        setTimeout(() => {
          navigate('/boi/extractor/clients/list');
        }, 2000);
      })
      .catch(() => {
        notif({
          type: 'error',
          content: "Une erreur est survenue lors de l'enregistrement du client"
        });
      })
      .finally(() => {
        setSubmitInProgress(false);
      });
  };

  if (!moduleFamilies || !anomalyProfiles) {
    return <></>;
  }

  return (
    <>
      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
          <h3>Chargement en cours...</h3>
        </Box>
      ) : (
        <Paper variant="outlined" square sx={{ p: 2 }}>
          <h3>{id ? 'Modifier le client' : 'Ajouter le client'}</h3>

          <Formik
            initialValues={client}
            enableReinitialize={true}
            onSubmit={async (values: any) => {
              saveClient(values);
            }}
            // validationSchema={Yup.object().shape({
            //   number: Yup.mixed().required('Champ obligatoire'),
            //   type: Yup.mixed().required('Champ obligatoire'),
            //   status: Yup.mixed().required('Champ obligatoire')
            // })}
          >
            {(props: any) => {
              const { errors, setSubmitting, handleSubmit, setFieldValue, values } = props;
              return (
                <form
                  id="topForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSubmitting(true);
                    // Check
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid alignItems={'center'} item xs={2}>
                      Nom
                    </Grid>
                    <Grid alignItems={'center'} item xs={4}>
                      <ClientNameField setFieldValue={setFieldValue} name={values.name} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid alignItems={'center'} item xs={5} justifySelf="right">
                      <CustomSwitchField
                        name="inter_auto_validation"
                        label="Validation des interventions automatiques"
                      />
                    </Grid>

                    <Grid alignItems={'center'} item xs={2}>
                      Description
                    </Grid>
                    <Grid alignItems={'center'} item xs={10}>
                      <InputField
                        name="description"
                        type={'textarea'}
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid alignItems={'center'} item xs={2}>
                          Familles de module
                        </Grid>
                        <Grid alignItems={'center'} item xs={4}>
                          <SelectField
                            name="module_families"
                            options={formatToSelectOptions(moduleFamilies, {
                              label: 'Name',
                              value: 'Id'
                            })}
                            isMulti
                          />
                        </Grid>
                        <Grid alignItems={'center'} item xs={2} sx={{ pl: 1 }}>
                          Profil d'anomalie
                        </Grid>
                        <Grid alignItems={'center'} item xs={4}>
                          <SelectField
                            name="anomaly_profile"
                            options={formatToSelectOptions(anomalyProfiles, { label: 'name' })}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <h4>Contacts à prévenir</h4>

                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      En cas d'anomalie sur une intervention
                      <br />
                      <SelectField
                        name="inter_issue_contact_frequency"
                        options={[
                          { value: 'ONCE A DAY', label: 'Une fois par jour' },
                          { value: 'ONCE A WEEK', label: 'Une fois par semaine' }
                        ]}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <InputField
                        name="inter_issue_contacts"
                        type={'textarea'}
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      En cas d'export réussi
                    </Grid>
                    <Grid item xs={8}>
                      <InputField
                        name="export_issue_contacts"
                        type={'textarea'}
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      En cas d'export en échec
                    </Grid>
                    <Grid item xs={8}>
                      <InputField
                        name="export_success_contacts"
                        type={'textarea'}
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <AssociatedERPs client={client} />
                  <h4>Liste des jobs associés</h4>
                  <ListPage
                    loadedData={client?.jobs || []}
                    fields={[
                      {
                        name: 'name',
                        label: 'Nom'
                      },
                      {
                        name: 'programming_type',
                        label: 'Programmation'
                      },
                      {
                        name: 'erp_name',
                        label: 'ERP'
                      },
                      {
                        name: 'export_format',
                        label: 'Format'
                      }
                    ]}
                    actions={[
                      {
                        name: 'voir',
                        render: (job: Job) => {
                          return (
                            <IconButton component={Link} to={`/boi/extractor/jobs/edit/${job.id}`}>
                              <Visibility fontSize="small" />
                            </IconButton>
                          );
                        }
                      },
                      {
                        name: 'delete-job',
                        label: 'Supprimer le job',
                        render: (model: any) => {
                          return (
                            <IconButton
                              onClick={() => {
                                confirmDialog({
                                  title: 'Supprimer le job',
                                  content: 'Êtes-vous sûr de vouloir supprimer ce job?',
                                  onValidate: () => {
                                    axios.delete(`/api/boi/extractor/jobs/${model.id}/`).then(
                                      () => {
                                        notif({
                                          content: 'Le job a été supprimé avec succès',
                                          type: 'success'
                                        });
                                        setTimeout(() => {
                                          navigate(0);
                                        }, 2000);
                                      },
                                      () => {
                                        notif({
                                          content: 'Une erreur est survenue lors de la suppression',
                                          type: 'error'
                                        });
                                      }
                                    );
                                    closeDialog();
                                  },
                                  onCancel: () => {
                                    closeDialog();
                                  }
                                });
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          );
                        }
                      }
                    ]}
                    displayResultsNumber={false}
                    displayPaginationOptions={false}
                  />

                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      form="topForm"
                      sx={{ m: 2 }}
                      disabled={submitInProgress}
                    >
                      {submitInProgress && <CircularProgress size={18} thickness={5} />} Enregistrer
                    </Button>

                    <Button
                      className="btn-space"
                      onClick={() => {
                        confirmDialog({
                          title: 'Annuler les saisies',
                          content: 'Souhaitez-vous annuler vos saisies ?',
                          onValidate: () => {
                            closeDialog();
                            navigate(-1);
                          },
                          onCancel: () => {
                            closeDialog();
                          },
                          okButtonLabel: 'Oui',
                          cancelButtonLabel: 'Non'
                        });
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
          <BirdzNotif options={notifOptions} />
          <BirdzDialog options={dialogOptions} />
        </Paper>
      )}
    </>
  );
};

export default ClientForm;
